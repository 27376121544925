import { makeAutoObservable, reaction } from 'mobx';

import { type FeedbackRequest, getUserFeedbackAnswer, sendFeedback } from 'api/feedback';
import { tabIds } from 'constants/ui';
import { TTabIds } from 'store/UIStore/UIStore';
import { BooleanField } from 'store/fileds/BooleanField';
import { StringField } from 'store/fileds/StringField';
import { followedLoadsTabOpenCounterStorage } from 'utils/storage/Storage';

import { RootStore } from '../RootStore';

export enum FeedbackPopupStates {
  initial = 'initial',
  liked = 'liked',
  disliked = 'disliked',
}

const counterTrigger = 5;

export class FeedbackPopupStore {
  readonly #root: RootStore;
  visible: boolean;
  isLoading: boolean;
  openCounter: number;
  state: FeedbackPopupStates = FeedbackPopupStates.initial;
  opinion: BooleanField;
  notUsing: BooleanField;
  confusing: BooleanField;
  noDifference: BooleanField;
  other: BooleanField;
  feedback: StringField;

  constructor(root: RootStore) {
    this.#root = root;

    this.visible = false;
    this.isLoading = false;
    this.openCounter = followedLoadsTabOpenCounterStorage.get() || 0;

    this.opinion = new BooleanField();

    this.notUsing = new BooleanField();
    this.confusing = new BooleanField();
    this.noDifference = new BooleanField();
    this.other = new BooleanField();
    this.feedback = new StringField();

    makeAutoObservable(this);

    reaction(
      () => this.#root.ui.activeTab,
      (tab: TTabIds) => {
        const isFollowedLoadsTabOpened = tab === tabIds.favorites;

        if (isFollowedLoadsTabOpened && this.#root.followedLoads.count > 0) {
          this.incrementCounter();
          this.showPopover();
        }
      },
    );
  }

  get feedbackParams(): FeedbackRequest {
    return {
      type: 'dislike',
      dont_want_to_use: this.notUsing.data,
      became_messy: this.confusing.data,
      no_difference: this.noDifference.data,
      feedback: this.feedback.value,
    };
  }

  setIsLoading = (value: boolean) => {
    this.isLoading = value;
  };

  incrementCounter = () => {
    this.openCounter = this.openCounter + 1;
    followedLoadsTabOpenCounterStorage.set(this.openCounter);
  };

  checkIfShouldShowPopover = async () => {
    if (this.openCounter % counterTrigger === 0) {
      const answer = await this.getUserFeedbackAnswer();
      return answer.survey_sent === false;
    }

    return false;
  };

  showPopover = async () => {
    const shouldShowPopover = await this.checkIfShouldShowPopover();

    if (shouldShowPopover) {
      this.open();
    }
  };

  like = () => {
    this.opinion.setData(true);
    this.vote({ type: 'like' });
  };

  dislike = () => {
    this.opinion.setData(true);
    this.vote({ type: 'dislike' });
  };

  vote = async (params: FeedbackRequest) => {
    if (this.isLoading) {
      return;
    }

    try {
      this.setIsLoading(true);

      if (params.type === 'dislike') {
        this.setState(FeedbackPopupStates.disliked);
        return;
      }

      if (params.type === 'like') {
        this.setState(FeedbackPopupStates.liked);
        sendFeedback(params);
        return;
      }

      if (params.type === 'close') {
        this.close();
        sendFeedback(params);
        return;
      }
    } catch (error) {
      this.close();
    } finally {
      this.setIsLoading(false);
    }
  };

  provideFeedback = async () => {
    if (this.isLoading) {
      return;
    }

    try {
      this.setIsLoading(true);
      sendFeedback(this.feedbackParams);
      this.close();
    } catch (error) {
      this.close();
    } finally {
      this.setIsLoading(false);
    }
  };

  getUserFeedbackAnswer = async () => {
    const { data } = await getUserFeedbackAnswer();
    return data;
  };

  setState = (state: FeedbackPopupStates) => {
    this.state = state;
  };

  setVisible = (visible: boolean) => {
    this.visible = visible;
  };

  open = () => {
    this.setVisible(true);
  };

  close = () => {
    this.setVisible(false);
  };

  refuseToVote = () => {
    if (this.state === FeedbackPopupStates.initial || this.state === FeedbackPopupStates.disliked) {
      this.vote({ type: 'close' });
    }

    this.close();
  };
}
