import { makeAutoObservable, reaction } from 'mobx';

import { currenciesPerHour, defaultCurrencyId, paymentTypes, rateTypes } from 'constants/payment';
import { RootStore } from 'store/RootStore';
import { SiteFeaturesIDs } from 'store/SiteFeatures/SiteFeatures';
import { FloatField } from 'store/fileds/FloatField';
import { SelectField } from 'store/fileds/SelectField';

class Rate {
  root;
  value;
  type;
  currencyTypeId;

  currency;
  totalRate;
  totalRateCurrency;
  ratePerKm;

  constructor(root: RootStore) {
    this.root = root;
    // на удаление вместе с СФ
    this.value = new FloatField();
    this.type = rateTypes.newFormat;
    this.currencyTypeId = new SelectField(this.root.options.getRateTypes, this.countryCurrencyPerKmType);

    // новый Rate
    this.currency = new SelectField(this.root.options.getPureCurrencies, this.countryCurrencyType);
    this.totalRate = new FloatField();
    this.totalRateCurrency = new SelectField(this.getCurrencyModifiers);
    this.ratePerKm = new FloatField();

    makeAutoObservable(this);

    reaction(
      () => Number(this.value.data) + this.currencyTypeId.data,
      () => {
        if (this.root.app.isDataSetting) return;

        if ((this.value.data || !this.currencyTypeId.isDefault) && !this.root.filter.extraParams.withRate) {
          this.root.filter.extraParams.addParam(paymentTypes.withRate);
        }
      },
    );

    reaction(
      () => this.root.dictionaries.data.currencyCountry.length + this.root.dictionaries.data.currencyTypes.length,
      () => {
        if (this.root.app.isDataSetting) return;

        if (!(this.root.dictionaries.data.currencyCountry.length && this.root.dictionaries.data.currencyTypes.length))
          return;

        if (this.root.dictionaries.data.currencyCountry.length + this.root.dictionaries.data.currencyTypes.length)
          if (this.countryCurrencyPerKmType && this.currencyTypeId.isDefault) {
            if (this.currencyTypeId.isDefault) {
              this.currencyTypeId.setOption(this.countryCurrencyPerKmType);
            }

            this.currencyTypeId.setDefaultOption(this.countryCurrencyPerKmType);
            return;
          }

        this.currencyTypeId.setOptionByValue(defaultCurrencyId);
        this.currencyTypeId.setCurrentOptionAsDefault();
      },
    );

    reaction(
      () => this.root.dictionaries.data.currencyCountry.length + this.root.dictionaries.data.currencyTypes.length,
      () => {
        if (this.root.app.isDataSetting) return;

        if (!(this.root.dictionaries.data.currencyCountry.length && this.root.dictionaries.data.currencyTypes.length))
          return;

        if (this.countryCurrencyType && this.currency.isDefault) {
          if (this.currency.isDefault) {
            this.currency.setOption(this.countryCurrencyType);
            this.currency.setDefaultOption(this.countryCurrencyType);

            return;
          }
        }
      },
    );
  }

  getCurrencyModifiers = () =>
    this.root.options
      .getRateTypes()
      .filter(
        currency =>
          currency.digitalCode === this.currency.option.digitalCode &&
          currency.id !== this.currency.option.currencyIdPerKm,
      );

  get ratePerKmCurrency() {
    return this.root.options
      .getRateTypes()
      .filter(currency => currency.id === this.currency.option.currencyIdPerKm)?.[0];
  }

  get hasCurrencyPerHourSelected() {
    return currenciesPerHour.includes(this.totalRateCurrency.data);
  }

  get isDefault() {
    return (
      this.value.isDefault &&
      this.currencyTypeId.isDefault &&
      this.currency.isDefault &&
      this.totalRate.isDefault &&
      this.totalRateCurrency.isDefault &&
      this.ratePerKm.isDefault
    );
  }

  get requestData() {
    if (this.root.siteFeatures.isSiteFeatureEnabled[SiteFeaturesIDs.doubleRateFilter]) {
      if ((!this.totalRate.data && !this.ratePerKm.data) || (this.hasCurrencyPerHourSelected && !this.totalRate.data))
        return null;

      if (this.hasCurrencyPerHourSelected && this.totalRate.data) {
        return {
          currencyId: this.currency.data,
          ratePerHour: this.totalRate.data,
        };
      }

      return {
        currencyId: this.currency.data,
        ...(this.totalRate.data && {
          rateTotal: this.totalRate.data * parseInt(this.totalRateCurrency.option.modifier),
        }),
        ...(this.ratePerKm.data && {
          ratePerKm: this.ratePerKm.data,
        }),
      };
    }

    if (!this.value.data) return null;

    return {
      ...(this.type === rateTypes.newFormat && { currencyTypeId: this.currencyTypeId.data }),
      type: this.type,
      value: this.value.data,
    };
  }

  get countryCurrencyPerKmType() {
    const {
      profile,
      dictionaries: {
        data: { currencyCountry },
      },
    } = this.root;
    const currencyTypes = this.root.options.getRateTypes();

    const currencyId = currencyCountry.find(item => parseInt(item.countryId) === profile.data?.profile?.countryId)?.id;

    const currencyType = currencyTypes.find(currency => currency.id.toString() === currencyId);

    const currencyTypePerKm = currencyTypes.find(currency => currency.id === currencyType?.currencyIdPerKm);

    return currencyTypePerKm;
  }

  get countryCurrencyType() {
    const {
      profile,
      dictionaries: {
        data: { currencyCountry },
      },
    } = this.root;
    const currencyTypes = this.root.options.getRateTypes();
    const currencyId = currencyCountry.find(item => parseInt(item.countryId) === profile.data?.profile?.countryId)?.id;
    const currencyType = currencyTypes.find(currency => currency.id.toString() === currencyId);

    return currencyType;
  }

  setType = (type: number) => {
    this.type = type;
  };

  reset = () => {
    this.value.reset();
    this.currencyTypeId.reset();

    // новый Rate
    this.currency.reset();
    this.totalRate.reset();
    this.totalRateCurrency.reset();
    this.ratePerKm.reset();
  };
}

export { Rate };
