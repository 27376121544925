import { rateTypes, rateTypesOldToNew } from 'constants/payment';
import { Specifications } from 'store/QueryStore/QueryStore';
import { capitalize } from 'utils/string/format';

import { FilterType } from '../types';

const OldToSearch = (oldFilter: IOldFilter): FilterType => {
  return {
    ellipse: mapEllipse(oldFilter),
    routeLength: mapRouteLength(oldFilter),
    from: mapFromGeoPoint(oldFilter, 'from'),
    to: mapFromGeoPoint(oldFilter, 'to'),
    weight: mapRange(oldFilter, 'weight'),
    volume: mapRange(oldFilter, 'volume'),
    dates: mapDates(oldFilter),
    loadingType: mapLoadingType(oldFilter.loadingType),
    truckType: oldFilter.truckType,
    cargoTypes: oldFilter.cargoTypes.map(cargoType => cargoType.toString()),
    extraParams: oldFilter.extraParams > 0 ? oldFilter.extraParams : undefined,
    rate: mapRates(oldFilter),
    withDimensions: oldFilter.withDimensions,
    withAuction: oldFilter.withAuction,
    excludeTenders: oldFilter.excludeTenders || null,
    width: mapRange(oldFilter, 'width'),
    length: mapRange(oldFilter, 'length'),
    height: mapRange(oldFilter, 'height'),
    pallets: oldFilter.pallets || null,
    boardList: oldFilter.boardList,
    sortingType: oldFilter.sortingType,
    changeDate: oldFilter.changeDate,
    dogruz: mapDogruz(oldFilter),
    firm: mapFirms(oldFilter),
    truckId: oldFilter.truckId,
  };
};

const mapFromGeoPoint = (oldFilter: IOldFilter, point: 'to' | 'from'): FilterGeoType | null => {
  if (!oldFilter[point] && !oldFilter[`${point}List`]) return null;

  const capPoint = capitalize(point) as 'To' | 'From';

  // TODO:
  // косяк, который нужно править на серверной стороне
  // если выбран эллипс, то галочки Только по Загрузке/Разгрузке
  // должны быть всегда true, сейчас от метода userfilters/tabs приходят false :(
  const isExactOnly = oldFilter.ellipse.enabled ? true : oldFilter[`exact${capPoint}Geos`];
  const radiusValue = oldFilter[`${point}Radius`];
  const geo = {
    exactOnly: isExactOnly,
    radius: radiusValue || undefined,
  };

  const standardGeo = oldFilter[`${point}Geo`];
  const listGeo = oldFilter[`${point}List`];

  if (listGeo) {
    return {
      ...geo,
      listType: listGeo?.type,
      listId: listGeo?.id,
      type: 5,
    };
  }

  if (standardGeo) {
    const [type, id] = standardGeo.split('_');

    return { ...geo, type: Number(type), id };
  }

  return { ...geo };
};

const mapEllipse = (oldFilter: IOldFilter): TEllipse | undefined => {
  if (!oldFilter.ellipse.enabled) return undefined;
  const {
    ellipse: { maxEnlargment, maxEnlargmentUnit, minLength, minLengthUnit },
  } = oldFilter;
  return { maxEnlargment, maxEnlargmentUnit, minLength, minLengthUnit };
};

const mapRange = (oldFilter: IOldFilter, propName: Specifications) => {
  const prop = oldFilter[propName];

  if (prop) {
    return {
      min: prop.from || null,
      max: prop.to || null,
    };
  }
  return null;
};

const mapRouteLength = (oldFilter: IOldFilter) => {
  const { enabled, min, max } = oldFilter.routeParams || {};

  if (!enabled) {
    return null;
  }

  return {
    min: min || null,
    max: max || null,
  };
};

const mapDates = (oldFilter: IOldFilter): TLoadDates => {
  const { dateFrom, dateTo, dateOption } = oldFilter;

  if (dateOption === 'manual') {
    return {
      dateOption,
      dateFrom,
      dateTo: dateTo || null,
    };
  }

  return {
    dateOption,
    dateFrom: null,
    dateTo: null,
  };
};

const mapLoadingType = (loadingType: number): string | undefined => {
  return loadingType > 0 ? loadingType.toString() : undefined;
};

const mapRates = (oldFilter: IOldFilter): TRate | TLegacyRate | null => {
  if (!oldFilter.rate) return null;

  let currencyTypeId = oldFilter.currencyTypeId;
  let type = oldFilter.rateType || rateTypes.newFormat;

  if (oldFilter.rateType && oldFilter.rateType !== rateTypes.newFormat) {
    currencyTypeId = rateTypesOldToNew[oldFilter.rateType];
    type = rateTypes.newFormat;
  }

  if (oldFilter.currencyId) {
    return {
      currencyId: oldFilter.currencyId,
      rateTotal: oldFilter.rateTotal,
      ratePerKm: oldFilter.ratePerKm,
      ratePerHour: oldFilter.ratePerHour,
    };
  }

  return {
    value: oldFilter.rate as number,
    type,
    currencyTypeId,
  };
};

const mapDogruz = (oldFilter: IOldFilter) => {
  const dorguzMap = {
    0: null,
    1: true,
    2: false,
  };

  return dorguzMap[oldFilter.dogruz];
};

const mapFirms = (oldFilter: IOldFilter): TFirms => {
  const firms = {
    firmListsExclusive: oldFilter.firmListsExclusive,
    firmListsInclusive: oldFilter.firmListsInclusive,
    firmRating: oldFilter.firmRating,
    atiId: oldFilter.firmId,
    ...(oldFilter.firmGeoIds && {
      firmGeo: {
        type: Number(oldFilter.firmGeoIds.split('_')[0]),
        id: oldFilter.firmGeoIds.split('_')[1],
      },
    }),
  };

  return firms;
};

export { OldToSearch };
