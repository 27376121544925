import { makeAutoObservable } from 'mobx';

import { TabType } from 'components/Tabs/Tab';
import { UserTheme } from 'components/ThemeSwitch/useTheme';
import { enablerFeatureTitles } from 'constants/features';
import { mediaQuery, tabIds } from 'constants/ui';
import { SelectField } from 'store/fileds/SelectField';
import { getInitialData } from 'utils/getInitialData';
import { compactPrettyLoadStorage } from 'utils/storage/Storage';

import { RootStore } from '../RootStore';
import { MobileScreens } from './MobileScreens';
import { Pagination } from './Pagination';
import { Popups } from './Popups';

export type TFollowedLoadsTabIds = keyof typeof followedLoadsTabIds;

export type TTabIds = keyof typeof tabIds;

export type TTabIdsQueryMap = typeof tabIdsQueryMap;

export const followedLoadsTabIds = {
  onlyWithOffers: 'onlyWithOffers',
  onlyWithFavorites: 'onlyWithFavorites',
  onlyWithAuctions: 'onlyWithAuctions',
} as const;

export const tabIdsQueryMap = {
  filters: tabIds.savedFilters,
  searchHistory: tabIds.history,
  trucks: tabIds.byTrucks,
  favorites: tabIds.favorites,
};

class UIStore {
  root;
  popups;
  isTabsPinned = false;
  activeTab: TTabIds;
  isAdvancedFiltersVisible = false;
  isAdditionalFiltersVisible = false;
  isDateOptionsExpanded = false;
  isSticky = false;
  shouldSearchByEnter: boolean = true;
  isDatePickerOpened: boolean = false;
  isPrintMode: boolean = false;
  isCompactPrettyLoad: boolean = compactPrettyLoadStorage.get();
  mobileScreens: MobileScreens;
  isPageFocused: boolean = true;
  isMobile: boolean;
  // Это костыль для того, чтобы правильно открывался дропдаун.
  // Позже хотелось бы от него избавиться.
  openedDropdownId: string | null = null;
  userTheme: UserTheme = 'default-theme';
  isNewFavoritesTabPopoverVisible = true;
  followedLoadsTab;
  pagination: Pagination;

  constructor(root: RootStore) {
    this.root = root;
    this.activeTab = this.tabs[0].id;
    this.followedLoadsTab = new SelectField(this.getFollowedLoadsTabs);
    this.popups = new Popups();
    this.mobileScreens = new MobileScreens();
    this.pagination = new Pagination(root);

    this.isMobile = getInitialData(
      'isMobile',
      typeof window !== 'undefined' && window.matchMedia(mediaQuery.mobile).matches,
    );

    makeAutoObservable(this);
    this.init();
  }

  startPageFocusListeners = () => {
    window.addEventListener('blur', this.setPageUnfocused, false);
    window.addEventListener('focus', this.setPageFocused, false);
  };

  init = () => {
    this.initMediaListeners();

    this.pagination.init();

    if (typeof window !== 'undefined') {
      this.startPageFocusListeners();
    }
  };

  get i18n() {
    return this.root.app.i18n;
  }

  get tabs(): TabType[] {
    const { tabs } = this.i18n.app;

    return [
      { label: tabs.search.label, id: tabIds.search, disabled: false },
      {
        label: tabs.savedFilters.label,
        id: tabIds.savedFilters,
        disabled: !this.root.profile.data?.isUser,
      },
      {
        label: tabs.history.label,
        id: tabIds.history,
        disabled: !this.root.profile.data?.isUser,
      },
      {
        label: tabs.byTrucks.label,
        id: tabIds.byTrucks,
        disabled: !this.root.profile.data?.isUser,
      },
      {
        label: tabs.chains.label,
        id: tabIds.chains,
        disabled: false,
        href: '/chains/',
      },
      {
        label: tabs.favorites.newLabel,
        id: tabIds.favorites,
        disabled: !this.root.profile.data?.isUser,
      },
    ];
  }

  get isActiveSavedFiltersTab() {
    return this.activeTab === tabIds.savedFilters;
  }

  getFollowedLoadsTabs = () => {
    const {
      followedLoads,
      app: { i18n },
    } = this.root;

    const favorites = followedLoads?.favorites;
    const loadsWithOffers = followedLoads?.loadsWithOffers;
    const loadsWithAuctions = followedLoads?.loadsWithAuctions;

    const hasFavorites = favorites?.count > 0;
    const hasLoadsWithOffers = loadsWithOffers?.count > 0;
    const hasLoadsWithAuctions = loadsWithAuctions?.count > 0;

    return [
      {
        label: `${i18n.favorites.tabs.onlyFavorites} ${hasFavorites ? favorites.count : ''}`,
        value: followedLoadsTabIds.onlyWithFavorites,
      },
      {
        label: `${i18n.favorites.tabs.onlyWithOffers} ${hasLoadsWithOffers ? loadsWithOffers.count : ''}`,
        value: followedLoadsTabIds.onlyWithOffers,
      },
      {
        label: `${i18n.favorites.tabs.onlyWithAuctions} ${hasLoadsWithAuctions ? loadsWithAuctions.count : ''}`,
        value: followedLoadsTabIds.onlyWithAuctions,
      },
    ];
  };

  get shouldShowNewFavoritesTabPopoverVisible() {
    return (
      this.root.profile.data?.isUser &&
      this.isNewFavoritesTabPopoverVisible &&
      this.root.enablerFeaturesRepo.featuresByTitle[enablerFeatureTitles.newFavoritesTabPopover]?.isEnabled &&
      !this.root.enablerFeaturesRepo.featuresByTitle[enablerFeatureTitles.newFavoritesTabPopover]?.isViewed
    );
  }

  setIsNewFavoritesTabPopoverVisible = (value: boolean) => {
    this.isNewFavoritesTabPopoverVisible = value;
  };

  closeNewFavoritesTabPopover = () => {
    this.setIsNewFavoritesTabPopoverVisible(false);
    this.root.enablerFeaturesRepo.markAsViewedByTitle(enablerFeatureTitles.newFavoritesTabPopover);
  };

  setUserTheme = (theme: UserTheme) => {
    this.userTheme = theme;
  };

  setTabFromQuery = () => {
    if (this.root.query.queryParams.has('tab')) {
      const tabName = tabIdsQueryMap?.[this.root.query.queryParams.get('tab') as keyof TTabIdsQueryMap];
      tabName && this.setActiveTab(tabName);
    }
  };

  setActiveTab = (tab: TTabIds) => {
    const [tabCandidate] = this.tabs.filter(tabOption => tabOption.id === tab);

    if (tabCandidate.disabled) return;

    this.activeTab = tab;
  };

  toggleTabsPin = () => {
    this.isTabsPinned = !this.isTabsPinned;
  };

  setIsAdvancedFiltersVisible = (isVisible: boolean) => {
    this.isAdvancedFiltersVisible = isVisible;
  };

  toggleIsAdvancedFiltersVisible = () => {
    this.setIsAdvancedFiltersVisible(!this.isAdvancedFiltersVisible);
  };

  setIsAdditionalFiltersVisible = (isVisible: boolean) => {
    this.isAdditionalFiltersVisible = isVisible;
  };

  toggleIsAdditionalFiltersVisible = () => {
    this.setIsAdditionalFiltersVisible(!this.isAdditionalFiltersVisible);
  };

  setIsDateOptionsExpanded = (isExpanded: boolean) => {
    this.isDateOptionsExpanded = isExpanded;
  };

  toggleIsDateOptionsExpanded = () => {
    this.setIsDateOptionsExpanded(!this.isDateOptionsExpanded);
  };

  setIsDatePickerOpened = (isDatePickerOpened: boolean) => {
    this.isDatePickerOpened = isDatePickerOpened;
  };

  setIsSticky = (value: boolean) => {
    this.isSticky = value;
  };

  setShouldSearchByEnter = (value: boolean) => {
    this.shouldSearchByEnter = value;
  };

  setPrintMode = (isPrintMode: boolean) => {
    this.isPrintMode = isPrintMode;
  };

  setIsCompactPrettyLoad = (isCompactPrettyLoad: boolean) => {
    this.isCompactPrettyLoad = isCompactPrettyLoad;
    compactPrettyLoadStorage.set(isCompactPrettyLoad);
  };

  setIsPageFocused = (isPageFocused: boolean) => {
    this.isPageFocused = isPageFocused;
  };

  setPageFocused = () => this.setIsPageFocused(true);
  setPageUnfocused = () => this.setIsPageFocused(false);

  openLoginPopup = () => {
    if (typeof window === 'undefined') return;
    if (window?.openLoginPopup) {
      window.addEventListener('message', this.handleUserLogin, false);
      window?.openLoginPopup({
        next: encodeURIComponent(window.location.href),
      });
    }
  };

  handleUserLogin = (event: MessageEvent) => {
    if (event.type !== 'message') return;

    if (event?.data === 'user.logged.in') {
      document.location.reload();
      window.removeEventListener('message', this.handleUserLogin, false);
    }
  };

  setOpenedDropdownId = (id: string | null) => {
    this.openedDropdownId = id;
  };

  setIsMobile = (isMobile: boolean) => {
    this.isMobile = isMobile;
  };

  initMediaListeners = () => {
    if (typeof window === 'undefined') return;

    const mobileMedia = window.matchMedia(mediaQuery.mobile);
    const mobileMediaListener = () => this.setIsMobile(mobileMedia.matches);
    mobileMediaListener();

    try {
      // Chrome, Firefox
      mobileMedia.addEventListener('change', mobileMediaListener);
    } catch {
      // Safari
      mobileMedia.addListener(mobileMediaListener);
    }
  };

  handleTabChange = (tab: TTabIds, subTab: TFollowedLoadsTabIds) => {
    this.setActiveTab(tab);

    if (subTab) {
      this.followedLoadsTab.setOptionByValue(subTab);
    }
  };
}

export { UIStore };
