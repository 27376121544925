import { makeAutoObservable } from 'mobx';

import type { RootStore } from 'store/RootStore';

import { Favorites } from './Favorites';
import { LoadsWithAuctions } from './LoadsWithAuctions';
import { LoadsWithOffers } from './LoadsWithOffers';

export class FollowedLoads {
  readonly #root: RootStore;
  favorites: Favorites;
  loadsWithOffers: LoadsWithOffers;
  loadsWithAuctions: LoadsWithAuctions;

  constructor(root: RootStore) {
    this.#root = root;
    this.favorites = new Favorites(this.#root);
    this.loadsWithOffers = new LoadsWithOffers(this.#root);
    this.loadsWithAuctions = new LoadsWithAuctions(this.#root);

    makeAutoObservable(this);
  }

  get count() {
    return this.favorites.count + this.loadsWithOffers.count + this.loadsWithAuctions.count;
  }
}
