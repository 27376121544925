import { makeAutoObservable } from 'mobx';

import { deleteSubscription } from 'api/userFilters';
import { addInvoice, personalAccountFaq } from 'constants/pages';
import { OldToSearch } from 'store/FilterStore/Mappers/OldToSearch';
import { FilterType } from 'store/FilterStore/types';
import { RootStore } from 'store/RootStore';
import { SavedFilter } from 'store/TabsDataStore/TabsDataStore';
import { hasActivePromotion } from 'store/utils';
import { calculateFilterHash } from 'utils/filterHash';
import { interpolate } from 'utils/string/format';

import { TCounters } from './types';

export type TNotification = {
  active: boolean | undefined;
  description: string;
  level: NotificationLevels;
  icon: NotificationIcons;
  link?: {
    text: string;
    url: string;
  };
  tooltip?: TTooltip[];
  type?: string;
};

type TTooltip = {
  title: string;
  text: string;
};

export type TSubscribedFilter = SavedFilter & {
  counters?: TCounters;
  notifications?: TNotification[];
  instance?: SubscribedFilter;
};

export enum NotificationLevels {
  critical = 'critical',
  warning = 'warning',
}

export enum NotificationIcons {
  exclamation = 'exclamation',
  stop = 'stop',
}

class SubscribedFilter {
  readonly #root: RootStore;
  savedFilter: SavedFilter;
  searchParameters: FilterType;

  constructor(root: RootStore, savedFilter: SavedFilter) {
    this.#root = root;
    this.savedFilter = savedFilter;
    this.searchParameters = OldToSearch(savedFilter.filter as unknown as IOldFilter);

    makeAutoObservable(this);
  }

  get hash(): string {
    return calculateFilterHash(this.searchParameters);
  }

  get data(): TSubscribedFilter {
    const data = {
      ...this.savedFilter,
      ...(this.savedFilter.subscription && {
        subscription: {
          ...this.savedFilter.subscription,
        },
      }),
      counters: this.#root.subscriptions?.counters?.statisticCounters[this.savedFilter.id],
      notifications: this.notifications,
      instance: this,
    };

    return data;
  }

  get i18n() {
    return this.#root.app.i18n;
  }

  get isPromotionSubscription() {
    return Boolean(this.savedFilter.subscription?.subscriptionPromotionEndDate);
  }

  get isFreeSubscription() {
    const isNoRate = this.savedFilter.subscription?.rate === 0;

    return isNoRate || hasActivePromotion(this.savedFilter.subscription);
  }

  get hasSubscription() {
    return Boolean(this.savedFilter.subscription);
  }

  get hasValidCounters() {
    return (this.notifications?.length === 1 && this.isSMSStopped.active) || !this.notifications?.length;
  }

  get hasCounters() {
    if (!this.hasValidCounters) return false;

    return (
      this.#root.subscriptions?.counters?.statisticCounters[this.savedFilter.id]?.hasNewLoads ||
      this.#root.subscriptions?.counters?.statisticCounters[this.savedFilter.id]?.hasUpdatedLoads
    );
  }

  get isVirtuallyBlocked() {
    return {
      active:
        this.savedFilter.subscription &&
        this.#root.subscriptions.counters?.isUserVirtualBlocked &&
        !this.isFreeSubscription &&
        !this.#root.profile.hasUnlimitedNotificationsLicense,
      description: '',
      level: NotificationLevels.critical,
      icon: NotificationIcons.stop,
      link: {
        url: interpolate(personalAccountFaq, { env: this.#root.app.env }),
        text: this.i18n.subscriptions.notifications.isVirtuallyBlocked.link.text,
      },
      tooltip: [
        {
          title: this.i18n.subscriptions.notifications.isVirtuallyBlocked.tooltip.title,
          text: this.i18n.subscriptions.notifications.isVirtuallyBlocked.tooltip.text,
        },
      ],
      type: 'funds',
    };
  }

  get hasUnavailableBoard() {
    return {
      active: this.savedFilter.boardsAvailabilityFlag === 2,
      description: this.i18n.subscriptions.notifications.hasUnavailableBoard.description,
      level: NotificationLevels.critical,
      icon: NotificationIcons.exclamation,
      tooltip: [
        {
          title: '',
          text: this.i18n.subscriptions.notifications.hasUnavailableBoard.tooltip.text,
        },
      ],
    };
  }

  get hasSomeUnavailableBoards() {
    return {
      active: this.savedFilter.boardsAvailabilityFlag === 1,
      description: this.i18n.subscriptions.notifications.hasSomeUnavailableBoards.description,
      level: NotificationLevels.warning,
      icon: NotificationIcons.exclamation,
      tooltip: [
        {
          title: '',
          text: this.i18n.subscriptions.notifications.hasSomeUnavailableBoards.tooltip.text,
        },
      ],
    };
  }

  get hasNotEnoughMoney() {
    return {
      active:
        this.savedFilter.subscription &&
        this.#root.subscriptions.counters?.isUserBalanceTooLow &&
        !this.isFreeSubscription,
      description: this.i18n.subscriptions.notifications.hasNotEnoughMoney.description,
      level: NotificationLevels.critical,
      icon: NotificationIcons.stop,
      link: {
        url: interpolate(addInvoice, { env: this.#root.app.env }),
        text: this.i18n.subscriptions.notifications.hasNotEnoughMoney.link.text,
      },
      tooltip: [
        {
          title: this.i18n.subscriptions.notifications.hasNotEnoughMoney.tooltip.title,
          text: this.i18n.subscriptions.notifications.hasNotEnoughMoney.tooltip.text,
        },
      ],
      type: 'funds',
    };
  }

  get hasPromotionSubscriptionEnded() {
    return {
      active: this.isPromotionSubscription && !hasActivePromotion(this.savedFilter.subscription),
      heading: this.i18n.subscriptions.notifications.hasPromotionSubscriptionEnded.filterPopup.heading,
      text: this.i18n.subscriptions.notifications.hasPromotionSubscriptionEnded.filterPopup.text,
      description: this.i18n.subscriptions.notifications.hasPromotionSubscriptionEnded.description,
      level: NotificationLevels.critical,
      icon: NotificationIcons.stop,
      link: {
        url: interpolate(addInvoice, { env: this.#root.app.env }),
        text: this.i18n.subscriptions.notifications.hasPromotionSubscriptionEnded.link.text,
        desc: this.i18n.subscriptions.notifications.hasPromotionSubscriptionEnded.link.desc,
      },
      tooltip: [
        {
          title: this.i18n.subscriptions.notifications.hasPromotionSubscriptionEnded.tooltip.title,
          text: this.i18n.subscriptions.notifications.hasPromotionSubscriptionEnded.tooltip.text,
        },
      ],
    };
  }

  get isSMSStopped() {
    return {
      active: this.#root.subscriptions.counters?.isUserSmsBalanceTooLow && this.savedFilter.subscription?.hasSmsChannel,
      description: this.i18n.subscriptions.notifications.isSMSPaused.description,
      level: NotificationLevels.critical,
      icon: NotificationIcons.stop,
      tooltip: [
        {
          title: this.i18n.subscriptions.notifications.isSMSPaused.tooltip.title,
          text: this.i18n.subscriptions.notifications.isSMSPaused.tooltip.text,
        },
      ],
    };
  }

  get isLimitReached() {
    return {
      active:
        this.#root.subscriptions.counters?.statisticCounters[this.savedFilter.id]?.isSubscriptionStopped ||
        (this.#root.subscriptions.counters?.isNotificationsLimitReached &&
          this.hasSubscription &&
          !this.isFreeSubscription),
      description: this.i18n.subscriptions.notifications.isLimitReached.description,
      level: NotificationLevels.warning,
      icon: NotificationIcons.exclamation,
      tooltip: [
        {
          title: '',
          text: this.i18n.subscriptions.notifications.isLimitReached.tooltip.text,
        },
      ],
    };
  }

  get hasError() {
    return Boolean(this.errorDescription.length);
  }

  get sortingScore() {
    let score = 0;
    if (this.hasSubscription) score += 2;
    if (this.hasError) score--;

    if (!this.hasSubscription && !this.hasError) score -= 2;

    return score;
  }

  get errorDescription() {
    return this.notifications
      .map(notification => {
        // Костыль, нужно поправить при удалении SF добавив description для isVirtuallyBlocked
        /* @ts-ignore */
        if (notification.type === 'funds' && !notification.description) return notification.tooltip?.[0]?.text;
        return notification.description;
      })
      .join(', ');
  }

  get summaryNotification() {
    return {
      active: this.notifications.length > 1,
      description: this.#root.view.pluralizedCount(this.notifications.length, 'issue'),
      level: NotificationLevels.warning,
      icon: NotificationIcons.exclamation,
      tooltip: this.notifications.flatMap(not => not.tooltip),
    };
  }

  get notifications() {
    const uniqueTypes: string[] = [];

    return [
      this.hasPromotionSubscriptionEnded,
      this.isVirtuallyBlocked,
      this.hasSomeUnavailableBoards,
      this.hasUnavailableBoard,
      this.hasNotEnoughMoney,
      this.isLimitReached,
      this.isSMSStopped,
    ].filter((notification: TNotification) => {
      const hasSameType = notification.type && uniqueTypes.includes(notification.type);

      if (!hasSameType && notification.active) {
        notification.type && uniqueTypes.push(notification.type);
        return true;
      }
      return false;
    });
  }

  get hasNotifications() {
    return Boolean(this.notifications.length);
  }

  get hasCriticalNotifications() {
    return this.notifications.some(not => not.level === NotificationLevels.critical);
  }

  unsubscribe = async () => {
    if (!this.savedFilter.subscription) return;

    try {
      this.#root.app.setLoading('deleteSubscription', true);

      await deleteSubscription(this.savedFilter.subscription.dynamicDescriptorId);
      await this.#root.tabsData.fetchTabsData();
    } catch (error) {
      this.#root.errors.setRetrievable({
        name: 'deleteSubscription',
        message: this.#root.app.i18n.errors.deleteSubscription,
      });
      console.error(error);
    } finally {
      this.#root.app.setLoading('deleteSubscription', false);
    }
  };
}

export { SubscribedFilter };
