import Menu from '@components/menu';
import { makeAutoObservable } from 'mobx';
import { ComponentProps } from 'react';

export type OptionBase<T> = {
  value: T;
  label: string;
};

export type OptionType<T, RestFields> = OptionBase<T> & RestFields;

export type MenuOption = NonNullable<ComponentProps<typeof Menu>['options']>[number];

class SelectField<T, RestFields> {
  getOptions;
  defaultOption?: OptionType<T, RestFields>;
  _option;

  constructor(getOptions: () => OptionType<T, RestFields>[], defaultOption?: OptionType<T, RestFields>) {
    this.defaultOption = defaultOption;

    this.getOptions = getOptions;
    this._option = this.defaultOption;

    makeAutoObservable(this);
  }

  get default() {
    return this.defaultOption || this.getOptions()[0];
  }

  get option() {
    return this.options.find(opt => opt.value === this._option?.value) || this.default;
  }

  get menuOptions() {
    return this.options.map(option => ({
      id: option.value,
      content: option.label,
    }));
  }

  get options() {
    return this.getOptions();
  }

  get isDefault() {
    return this.option?.value === this.default?.value;
  }

  get data(): T {
    return this.option?.value;
  }

  setCurrentOptionAsDefault = () => {
    this._option && this.setDefaultOption(this._option);
  };

  setDefaultOption = (option: OptionType<T, RestFields>) => {
    this.defaultOption = option;
  };

  setOption = (option: OptionType<T, RestFields>) => {
    this._option = option;
  };

  setOptionFromMenu = (menuOption: MenuOption) => {
    if (menuOption.id) {
      this.setOptionByValue(menuOption.id as T);
    }
  };

  setOptionByValue = (value: T) => {
    const option = this.options.find(option => option.value === value);
    if (option) {
      this.setOption(option);
    }
  };

  setData = (key: keyof OptionType<T, RestFields>, value: any) => {
    const [option] = this.options.filter(option => option[key] === value);

    if (option) {
      this.setOption(option);
    }
  };

  reset = () => {
    this.setOption(this.default);
  };
}

export { SelectField };
