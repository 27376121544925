import { makeAutoObservable } from 'mobx';

import { getFollowedAuctions } from 'api/auctions';
import { getLoadsByIds } from 'api/search';
import { RootStore } from 'store/RootStore';
import { SelectedLoads } from 'store/UIStore/SelectedLoads';
import { LoadType } from 'types/cargosApp/Load';

export class LoadsWithAuctions {
  readonly #root: RootStore;
  loadsWithAuctions: LoadType[];
  count: number = 0;
  selectedLoads: SelectedLoads;

  constructor(root: RootStore) {
    this.#root = root;
    this.loadsWithAuctions = [];
    this.selectedLoads = new SelectedLoads();

    makeAutoObservable(this);
  }

  get loads() {
    if (this.loadsWithAuctions.length) {
      return this.loadsWithAuctions.map(load => ({
        ...load,
        checked: this.selectedLoads.ids.includes(load.id),
      }));
    }

    return [];
  }

  updateLoad = (updatedLoad: LoadType) => {
    const indexToUpdate = this.loadsWithAuctions?.findIndex(load => load.id == updatedLoad.id);

    if (this.loadsWithAuctions && typeof indexToUpdate === 'number') {
      this.loadsWithAuctions[indexToUpdate] = updatedLoad;
    }
  };

  setCount = (count: number) => {
    this.count = count;
  };

  setLoads = (loads: LoadType[]) => {
    this.loadsWithAuctions = loads;
  };

  updateLoads = async () => {
    await this.fetchLoadsWithAuctions();
  };

  fetchLoadsWithAuctions = async () => {
    try {
      const { data: auctionsData } = await getFollowedAuctions({
        pageNumber: this.#root.ui.pagination.pages.onlyWithAuctions,
        itemsPerPage: this.#root.ui.pagination.loadsWithAuctionsPerPage.data,
        sortingType: 'ByDateDesc',
      });

      if (auctionsData.auctions_count !== 0) {
        const loadsIds = auctionsData.auctions.map(load => load.cargo_application_id);
        const { data } = await getLoadsByIds(loadsIds);

        this.setLoads(data.loads);
        this.setCount(auctionsData.auctions_count);
      }
    } catch (error) {
      this.#root.errors.setRetrievable({
        name: 'fetchLoadsWithAuctions',
        message: this.#root.app.i18n.errors.getLoadsWithAuctions,
      });
    }
  };
}
