import axios from '../axios';

type FeebackAnswerResponse = {
  survey_sent: boolean;
  when: string;
};

type FeedbackAnswerCloseRequest = {
  type: 'close';
};

type FeedbackAnswerLikeRequest = {
  type: 'like';
};

type FeedbackAnswerDislikeRequest = {
  type: 'dislike';
  dont_want_to_use?: boolean;
  became_messy?: boolean;
  no_difference?: boolean;
  feedback?: string;
};

export type FeedbackRequest = FeedbackAnswerCloseRequest | FeedbackAnswerLikeRequest | FeedbackAnswerDislikeRequest;

export const getUserFeedbackAnswer = () => {
  return axios.get<FeebackAnswerResponse>('/gw/favorites/web/v1/cargos/favorites/followed-cargos-survey');
};

export const sendFeedback = (data: FeedbackRequest) => {
  return axios.post<FeebackAnswerResponse>('/gw/favorites/web/v1/cargos/favorites/followed-cargos-survey', data);
};
