import axios from './axios';

export type TGetFollowedAuctionsRequest = {
  pageNumber: number;
  itemsPerPage: number;
  sortingType: 'ByDateDesc';
};

export type TLoadAuction = {
  auction_id: string;
  cargo_application_id: string;
};

export type TFollowedAuctions = {
  persistent_user_id: number;
  auctions: TLoadAuction[];
  auctions_count: number;
};

export const getFollowedAuctions = (params: TGetFollowedAuctionsRequest) => {
  return axios.get<TFollowedAuctions>('/gw/favorites/web/v1/followed-auctions', { params });
};
