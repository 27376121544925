import { makeAutoObservable } from 'mobx';

import { ChangeDate } from 'constants/changeDate';
import { extraParams } from 'constants/extraParams';
import { loadTypes } from 'constants/loadTypes';
import { paymentTypes } from 'constants/payment';
import { distanceOptions } from 'constants/routeParams';
import { SortingTypes } from 'constants/sorting';
import { RootStore } from 'store/RootStore';

interface IOptionsStore {
  root: RootStore;
}

export enum AdditionalNotificationTypes {
  none,
  edited,
  rateRased,
}

class OptionsStore implements IOptionsStore {
  root;

  constructor(root: RootStore) {
    this.root = root;

    makeAutoObservable(this);
  }

  get i18n() {
    return this.root.app.i18n;
  }

  getLoadTypes = () => [
    { value: loadTypes.any, label: this.i18n.dimensions.loadTypes.options.any },
    { value: loadTypes.ltl, label: this.i18n.dimensions.loadTypes.options.ltl },
    { value: loadTypes.ftl, label: this.i18n.dimensions.loadTypes.options.ftl },
  ];

  getEllipseDistance = () => [
    { value: distanceOptions.percent, label: this.i18n.common.percentSign },
    { value: distanceOptions.km, label: this.i18n.common.km },
  ];

  getExtraParams = () => [
    { id: extraParams.withKoniki, name: this.i18n.extraParams.options.withKoniki.label, isExtraParam: true },
    {
      id: extraParams.adr,
      name: this.i18n.extraParams.options.adr.label,
      isExtraParam: true,
    },
    { id: extraParams.roundTrip, name: this.i18n.extraParams.options.roundTrip.label, isExtraParam: true },
    { id: extraParams.permanent, name: this.i18n.extraParams.options.permanent.label, isExtraParam: true },
    { id: extraParams.tenders, name: this.i18n.extraParams.options.tenders.label, isExtraParam: true },
  ];

  getPaymentTypes = () => [
    { id: paymentTypes.withPrepayment, name: this.i18n.payment.options.withPrepayment, isPaymentType: true },
    { id: paymentTypes.withoutRate, name: this.i18n.payment.options.withoutRate, isPaymentType: true },
    { id: paymentTypes.cash, name: this.i18n.payment.options.cash, isPaymentType: true },
    { id: paymentTypes.rateWithNDS, name: this.i18n.payment.options.rateWithNDS, isPaymentType: true },
    { id: paymentTypes.rateWithoutNDS, name: this.i18n.payment.options.rateWithoutNDS, isPaymentType: true },
    { id: paymentTypes.withRate, name: this.i18n.payment.options.withRate, isPaymentType: true },
  ];

  getRateTypes = () =>
    this.root.dictionaries.data.currencyTypes.map(currency => ({
      ...currency,
      label: currency.name,
      value: currency.id,
    }));

  getPureCurrencies = () => this.getRateTypes().filter(currency => currency.isCurrency);

  getChangeDate = () => [
    { value: ChangeDate.any, label: this.i18n.time.any },
    { value: ChangeDate.hours1, label: this.i18n.time.hours1 },
    { value: ChangeDate.hours3, label: this.i18n.time.hours3 },
    { value: ChangeDate.today, label: this.i18n.time.today },
    { value: ChangeDate.days3, label: this.i18n.time.days3 },
    { value: ChangeDate.week, label: this.i18n.time.week },
  ];

  getSortingTypes = () => [
    { value: SortingTypes.changeDate, label: this.i18n.sorting.changeDate },
    { value: SortingTypes.rubPerKm, label: this.i18n.sorting.rubPerKm },
    { value: SortingTypes.loadingDate, label: this.i18n.sorting.loadingDate },
    { value: SortingTypes.cargoType, label: this.i18n.sorting.cargoType },
    { value: SortingTypes.addDate, label: this.i18n.sorting.addDate },
  ];

  getRatingTypes = () => [
    {
      value: 0,
      label: this.i18n.firms.rating.any,
    },
    {
      value: 5,
      label: this.i18n.firms.rating.five,
    },
    {
      value: 4,
      label: this.i18n.firms.rating.four,
    },
    {
      value: 3,
      label: this.i18n.firms.rating.three,
    },
    {
      value: 2,
      label: this.i18n.firms.rating.two,
    },
    {
      value: 1,
      label: this.i18n.firms.rating.one,
    },
  ];

  getExclusiveTypes = () => [
    {
      value: false,
      label: this.i18n.firms.exclusiveTypes.byList,
    },
    {
      value: true,
      label: this.i18n.firms.exclusiveTypes.exclusive,
    },
  ];

  getItemsPerPageParams = () => [
    { label: '10', value: 10 },
    { label: '20', value: 20 },
    { label: '30', value: 30 },
    { label: '40', value: 40 },
    { label: '50', value: 50 },
    { label: '100', value: 100 },
  ];

  getLoadNotificationsTypes = () => [
    { label: this.i18n.filterPopup.notificationTypes.none.label, value: AdditionalNotificationTypes.none },
    { label: this.i18n.filterPopup.notificationTypes.edited.label, value: AdditionalNotificationTypes.edited },
    { label: this.i18n.filterPopup.notificationTypes.rateRased.label, value: AdditionalNotificationTypes.rateRased },
  ];
}

export { OptionsStore };
