import { makeAutoObservable } from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';

import { TI18n } from 'localization/types';

import { AppStore } from './AppStore';
import { Dictionaries } from './Dictionaries';
import { EnablerFeaturesRepo } from './EnablerFeaturesRepo';
import { ErrorsStore } from './ErrorsStore';
import { FeedbackPopupStore } from './FeedbackPopupStore';
import { FilterStore } from './FilterStore';
import { BoardsRepo } from './FilterStore/BoardsStore/BoardsRepo';
import { FollowedLoads } from './FollowedLoads';
import { MobileDevices } from './MobileDevices';
import { OptionsStore } from './OptionsStore';
import { ProfileStore } from './ProfileStore';
import { QueryStore } from './QueryStore';
import { SavedFiltersStore } from './SavedFiltersStore';
import { SiteFeatures } from './SiteFeatures';
import { Subscriptions } from './Subscriptions';
import { TabsDataStore } from './TabsDataStore';
import { UIStore } from './UIStore';
import { ViewStore } from './ViewStore/ViewStore';

enableStaticRendering(typeof window === 'undefined');

export interface IRootStore {
  dictionaries: Dictionaries;
  profile: ProfileStore;
  app: AppStore;
  ui: UIStore;
  options: OptionsStore;
  errors: ErrorsStore;
  boardsRepo: BoardsRepo;
  filter: FilterStore;
  query: QueryStore;
  tabsData: TabsDataStore;
  clearFilter: () => void;
  savedFilters: SavedFiltersStore;
  subscriptions: Subscriptions;
  mobileDevices: MobileDevices;
  view: ViewStore;
  enablerFeaturesRepo: EnablerFeaturesRepo;
  siteFeatures: SiteFeatures;
  followedLoads: FollowedLoads;
  feedbackPopup: FeedbackPopupStore;
}

class RootStore implements IRootStore {
  app: AppStore;
  profile: ProfileStore;
  dictionaries: Dictionaries;
  siteFeatures: SiteFeatures;
  options: OptionsStore;
  boardsRepo: BoardsRepo;
  filter: FilterStore;
  errors: ErrorsStore;
  query: QueryStore;
  tabsData: TabsDataStore;
  ui: UIStore;
  savedFilters: SavedFiltersStore;
  subscriptions: Subscriptions;
  mobileDevices: MobileDevices;
  view: ViewStore;
  followedLoads: FollowedLoads;
  enablerFeaturesRepo: EnablerFeaturesRepo;
  feedbackPopup: FeedbackPopupStore;

  constructor(i18n: TI18n) {
    this.app = new AppStore(this, i18n);
    this.profile = new ProfileStore(this);
    this.dictionaries = new Dictionaries(this);
    this.siteFeatures = new SiteFeatures();
    this.options = new OptionsStore(this);
    this.boardsRepo = new BoardsRepo(this);
    this.filter = new FilterStore(this);
    this.errors = new ErrorsStore(this);
    this.query = new QueryStore(this);
    this.tabsData = new TabsDataStore(this);
    this.ui = new UIStore(this);
    this.savedFilters = new SavedFiltersStore(this);
    this.subscriptions = new Subscriptions(this);
    this.mobileDevices = new MobileDevices(this);
    this.view = new ViewStore(this);
    this.followedLoads = new FollowedLoads(this);
    this.enablerFeaturesRepo = new EnablerFeaturesRepo();
    this.feedbackPopup = new FeedbackPopupStore(this);

    makeAutoObservable(this);
  }

  clearFilter = () => {
    this.filter = new FilterStore(this);
  };
}

export { RootStore };
